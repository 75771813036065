import React, { forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const BrButton = forwardRef((props, ref) => {
  const {
    children,
    className,
    circle,
    primary,
    secondary,
    large,
    small,
    disabled,
    loading,
    submit,
    ...rest
  } = props;

  const classes = classnames("br-button", className, {
    circle,
    primary,
    secondary,
    large,
    small,
    loading
  });

  const isDisabled = disabled;
  const isSubmit = submit;

  return (
    <button
      ref={ref}
      disabled={isDisabled}
      className={classes}
      type={isSubmit ? "submit" : "button"}
      {...rest}
    >
      {children}
    </button>
  );
});

BrButton.propTypes = {
  submit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  circle: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

BrButton.defaultProps = {
  submit: false,
  circle: false,
  primary: false,
  secondary: false,
  large: false,
  small: false,
  disabled: false,
  loading: false
};

export default BrButton;
