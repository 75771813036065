import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FixedSizeList } from "react-window";
import "./styles.scss";

/** número mínimo de options do select para ativar o uso de virtualização no ListBox */
const LISTBOX_VIRTUALIZATION_UP_TO = 100;

/** pixels de padding vertical extra para as options do select */
const LISTBOX_PADDING = 6;

const BrSelectOption = props => {
  const { data, index, style, value, multiple, getOptionProps } = props;
  const rowStyle = { ...style, top: style.top + LISTBOX_PADDING };

  const option = Array.isArray(data) ? data[index] : data;
  const selected =
    value && Array.isArray(value) && value.some(e => e.value === option.value);
  const rbIndex = `rb-${index}`;

  return (
    <div
      {...getOptionProps({ option, index })}
      className={classnames("item", "divider")}
      style={rowStyle}
    >
      <div className="content">
        <div
          className={classnames(
            { "br-radio": !multiple },
            { "br-checkbox": multiple }
          )}
        >
          <input
            id={rbIndex}
            type={multiple ? "checkbox" : "radio"}
            {...(selected && { defaultChecked: "checked" })}
          />
          <label htmlFor={rbIndex}>{option.label}</label>
        </div>
      </div>
    </div>
  );
};

BrSelectOption.propTypes = {
  /** Array de objetos (pra quando BrSelectOption for uma referência de função no FixedSizeList) ou,
   *  um único objeto (pra quando BrSelectOption for chamado como componente)
   *  contendo os dados de cada option do select
   * */
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    ),
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ]).isRequired,

  /** Índice da option */
  index: PropTypes.number.isRequired,

  /** Referência à função homônima devolvida pelo hook useAutocomplete */
  getOptionProps: PropTypes.func.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,

  /** Altura de cada elemento option do select */
  itemSize: PropTypes.number,

  /** Object que representa a option selecionada ou a string informada no input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ]),

  multiple: PropTypes.bool
};

BrSelectOption.defaultProps = {
  style: { top: 1 },
  itemSize: 48,
  value: "",
  multiple: false
};

const BrSelectListBox = ({
  getListboxProps,
  getOptionProps,
  options,
  itemSize,
  value,
  multiple,
  ...rest
}) => {
  const itemCount = options.length;
  const isLargeList = itemCount > LISTBOX_VIRTUALIZATION_UP_TO;

  return (
    <div className="br-list" {...getListboxProps()} {...rest} expanded="true">
      {!isLargeList &&
        options.map((option, index) => (
          <BrSelectOption
            key={`option-key-${index}`}
            data={option}
            index={index}
            value={value}
            multiple={multiple}
            getOptionProps={getOptionProps}
          />
        ))}

      {isLargeList && (
        <FixedSizeList
          itemData={options}
          height={(itemSize + 2) * LISTBOX_PADDING}
          width="100%"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {rowProps =>
            BrSelectOption({ value, multiple, getOptionProps, ...rowProps })}
        </FixedSizeList>
      )}
    </div>
  );
};

BrSelectListBox.propTypes = {
  /** Referência à função homônima devolvida pelo hook useAutocomplete   */
  getListboxProps: PropTypes.func.isRequired,

  /** Referência à função homônima devolvida pelo hook useAutocomplete   */
  getOptionProps: PropTypes.func.isRequired,

  /** Array de objetos contendo os dados de cada option do select */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,

  /** Altura de cada elemento option do select  */
  itemSize: PropTypes.number,

  /** Object que representa a option selecionada ou a string informada no input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ]),
  multiple: PropTypes.bool
};

BrSelectListBox.defaultProps = {
  itemSize: 48,
  value: "",
  multiple: false
};

export default BrSelectListBox;
