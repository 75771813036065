import classnames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";

const BrAvatar = forwardRef((props, ref) => {
  const { large, small, medium, src, alt, onHandleClickBtn, ...rest } = props;

  const classes = classnames("br-avatar", {
    large,
    small,
    medium
  });

  const [isExpanded, setExpanded] = useState(false);

  const handleClick = e => {
    setExpanded(!isExpanded);
    if (onHandleClickBtn) {
      onHandleClickBtn(e);
    }
  };

  const classesBtnMenuUsuario = classnames("fas", {
    "fa-times": isExpanded,
    "fa-angle-down": !isExpanded
  });

  return (
    <div className="d-flex align-items-center">
      <div ref={ref} className={classes} {...rest}>
        <div className="image">
          {!src && <i className="fas fa-user" aria-hidden="true" />}
          {src && alt && <img src={src} alt={alt} />}
        </div>
      </div>
      {onHandleClickBtn && (
        <button
          className="br-button circle small"
          type="button"
          aria-label="Abrir menu de usuário"
          onClick={e => handleClick(e)}
        >
          <i className={classesBtnMenuUsuario} aria-hidden="true" />
        </button>
      )}
    </div>
  );
});

BrAvatar.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  onHandleClickBtn: PropTypes.func
};

BrAvatar.defaultProps = {
  small: false,
  medium: false,
  large: false,
  src: "",
  alt: "",
  onHandleClickBtn: undefined
};

export default BrAvatar;
