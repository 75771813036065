import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Crumb = ({ isHome, label, onClick }) => {
  const handleClick = event => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <li className={classnames("crumb", { home: isHome })}>
      {!isHome && <i className="text-center icon fas fa-chevron-right" />}
      <a onClick={handleClick} href="#/">
        {isHome && (
          <>
            <span className="sr-only">{label}</span>
            <i className="icon fas fa-home" />
          </>
        )}
        {!isHome && label}
      </a>
    </li>
  );
};

Crumb.propTypes = {
  isHome: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Crumb.defaultProps = {
  isHome: false,
  onClick: () => {}
};

const BrBreadcrumb = ({ children, className }) => (
  <div className={classnames("br-breadcrumb", className)}>
    <ul className="crumb-list">{children}</ul>
  </div>
);

BrBreadcrumb.Crumb = Crumb;

BrBreadcrumb.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(BrBreadcrumb.Crumb)
  ]).isRequired
};

export default BrBreadcrumb;
