import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import BrAvatar from "../BrAvatar";

const BrHeader = ({
  sticky,
  compact,
  logo,
  linkLogo,
  handleClickLogo,
  sign,
  title,
  subTitle,
  links,
  functions,
  loginWidget,
  handleOpenMenu,
  searchWidget,
  handleOpenUserMenu,
  noLinks,
  noLogo,
  noSign,
  noSubtitle,
  noFunctions,
  noLogin,
  noSearch,
  noMenu,
  menu,
  UserMenu
}) => {
  const headerProps = {
    ...(sticky && { sticky: "" }),
    ...(compact && { "data-compact": "" }),
    ...(noLinks && { "data-no-links": "" }),
    ...(noLogo && { "data-no-logo": "" }),
    ...(noSign && { "data-no-sign": "" }),
    ...(noSubtitle && { "data-no-subtitle": "" }),
    ...(noFunctions && { "data-no-functions": "" }),
    ...(noLogin && { "data-no-login": "" }),
    ...(noSearch && { "data-no-search": "" })
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    if (searchOpen) {
      document.getElementById(searchWidget.inputId).focus();
    }
    // eslint-disable-next-line
  }, [searchOpen]);

  const handleSearch = onSearch => {
    setSearchOpen(false);
    if (onSearch) {
      onSearch(searchInputValue);
    }
  };

  const renderAvatar = () => {
    const { user } = loginWidget;
    const { avatar, totalNotifications, avatarRef } = user;
    return (
      <div className="avatar">
        <div
          className={`${
            totalNotifications || totalNotifications === 0 ? "br-badge" : ""
          } ${totalNotifications > 0 ? " is-danger" : ""}`}
        >
          <div className="icon">{totalNotifications}</div>
          <div className="content">
            <BrAvatar
              ref={avatarRef}
              src={avatar}
              onHandleClickBtn={handleOpenUserMenu}
              small
              style={{ cursor: "pointer", display: "flex" }}
              alt={`Avatar de ${user}`}
            />
            <UserMenu />
          </div>
        </div>
      </div>
    );
  };

  const renderSearchWidget = () => {
    const {
      placeholder = "O que você procura?",
      ariaLabel = "Texto da pesquisa",
      onSearch,
      inputId = "search-input-id"
    } = searchWidget;

    return (
      <div
        className="search"
        {...(searchOpen && { active: "" })}
        {...(compact && {
          style: { height: headerHeight }
        })}
      >
        <div className="br-input has-icon">
          <input
            id={inputId}
            value={searchInputValue}
            onChange={event => setSearchInputValue(event.target.value)}
            onFocus={() => {
              if (!searchOpen) {
                setSearchOpen(true);
              }
            }}
            onKeyDown={event => {
              const { key } = event;
              if (key === "Enter") {
                handleSearch(onSearch);
              } else if (key === "Escape") {
                setSearchOpen(false);
              }
            }}
            className="has-icon"
            type="text"
            placeholder={placeholder}
            aria-label={ariaLabel}
          />
          <button
            className="icon"
            type="button"
            aria-label="Pesquisar"
            onClick={() => handleSearch(onSearch)}
          >
            <span className="icon">
              <i className="fas fa-search" aria-hidden="true" />
            </span>
          </button>
        </div>
        <button
          className="br-button circle small search-close"
          type="button"
          aria-label="Fechar Busca"
          onClick={() => setSearchOpen(false)}
        >
          <i className="fas fa-times" aria-hidden="true" />
        </button>
      </div>
    );
  };

  const renderPopmenuSearch = () => (
    <div className="popmenu" {...(searchOpen && { active: "" })}>
      {renderSearchWidget()}
    </div>
  );

  return (
    <header ref={headerRef} className="br-header" {...headerProps}>
      <div className="container-lg">
        {menu}
        <div className="flex-container">
          {compact && renderPopmenuSearch()}
          <div className="logo">
            {handleClickLogo && (
              /* eslint-disable-next-line */
              <Link style={{ cursor: "pointer" }} onClick={handleClickLogo}>
                <img src={logo} alt="logo" />
              </Link>
            )}
            {!handleClickLogo && (
              <Link href={linkLogo}>
                <img src={logo} alt="logo" />
              </Link>
            )}
            <div className="sign">{sign}</div>
          </div>
          <div className="actions">
            {links && links.length > 0 && (
              <div className="links">
                <ul>
                  <li className="title">Acesso Rápido</li>
                  {links.map(({ url, label, target }, index) => (
                    <li key={index}>
                      <a href={url} target={`_${target}`}>
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
                <button
                  className="br-button circle small d-none"
                  type="button"
                  aria-label="Abrir Acesso Rápido"
                >
                  <i className="fas fa-ellipsis-v" aria-hidden="true" />
                </button>
              </div>
            )}
            {functions && functions.length > 0 && (
              <div className="functions">
                <ul>
                  <li className="title">Funcionalidades do Sistema</li>
                  {functions.map(({ label, onClick, iconClass }, index) => (
                    <li key={index}>
                      <button
                        className="br-button circle small"
                        type="button"
                        aria-label={label}
                        onClick={onClick}
                      >
                        <i className={iconClass} aria-hidden="true" />
                        <span className="text">{label}</span>
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  className="br-button circle small d-none"
                  type="button"
                  aria-label="Abrir Funcionalidades do Sistema"
                >
                  <i className="fas fa-th" aria-hidden="true" />
                </button>
              </div>
            )}
            <div className="search-btn">
              <button
                className="br-button circle small"
                type="button"
                aria-label="Abrir Busca"
                onClick={() => setSearchOpen(true)}
              >
                <i className="fas fa-search" aria-hidden="true" />
              </button>
            </div>
            {loginWidget && loginWidget.show && !loginWidget.user && (
              <div className="login">
                <button
                  className="br-button small"
                  type="button"
                  aria-label={loginWidget.label}
                  onClick={loginWidget.onClick}
                >
                  <i className="fas fa-user" aria-hidden="true" />
                  <span className="ml-1">{loginWidget.label}</span>
                </button>
              </div>
            )}
            {loginWidget && loginWidget.user && renderAvatar()}
          </div>
          <div className="menu">
            {noMenu ? (
              <span style={{ width: 35 }} />
            ) : (
              <button
                onClick={handleOpenMenu}
                className="br-button circle small"
                type="button"
                aria-label="Abrir Menu Principal"
              >
                <i className="fas fa-bars" aria-hidden="true" />
              </button>
            )}
            <div className="title">{title}</div>
            <div className="subtitle">{subTitle}</div>
          </div>
          {renderSearchWidget()}
        </div>
      </div>
    </header>
  );
};

BrHeader.propTypes = {
  /** Define se o menu deve ficar posicionado no topo ao rolar a tela */
  sticky: PropTypes.bool,

  /** Define se deve ser usado o modo compacto */
  compact: PropTypes.bool,

  /** URL da logo (img) ou imagem importada de arquivo SVG, PNG, JPG, etc */
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Link a ser acionado ao clicar na logo */
  linkLogo: PropTypes.string,

  /** Função será aciona ao clicar no logo, ignorando a propriedade linkLogo anterior */
  handleClickLogo: PropTypes.func,

  /** Assinatura da logo */
  sign: PropTypes.string,

  /** Título do sistema */
  title: PropTypes.string,

  /** Subtítulo do sistema */
  subTitle: PropTypes.string,

  /** Abre o menu principal */
  handleOpenMenu: PropTypes.func,

  /** Suprime a exibição do logo */
  noLogo: PropTypes.bool,

  /** Suprime a exibição dos links */
  noLinks: PropTypes.bool,

  /** Suprime a exibição da assinatura do sistema */
  noSign: PropTypes.bool,

  /** Suprime a exibição do subtítulo */
  noSubtitle: PropTypes.bool,

  /** Suprime a exibição da barra funções do sistema */
  noFunctions: PropTypes.bool,

  /** Suprime a exibição do botão de login */
  noLogin: PropTypes.bool,

  /** Suprime a exibição do widget de pesquisa */
  noSearch: PropTypes.bool,

  /** Suprime a exibição do botão de menu */
  noMenu: PropTypes.bool,

  /** Callback executado ao clicar no avatar do usuário */
  handleOpenUserMenu: PropTypes.func,

  /** Links de acesso rápidp */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
      target: PropTypes.oneOf(["self", "blank"])
    })
  ),

  /** Funcionalidades do sistema */
  functions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      iconClass: PropTypes.string
    })
  ),

  /** Dados de Login do Usuário */
  loginWidget: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      document: PropTypes.string,
      documentType: PropTypes.string,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      totalNotifications: PropTypes.number,
      avatarRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    })
  }),

  /** Configurações do widget de pesquisa */
  searchWidget: PropTypes.shape({
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    onSearch: PropTypes.func,
    inputId: PropTypes.string
  }),

  /** Menu principal do sistema (scrim menu) */
  menu: PropTypes.node,

  /** Menu do usuário */
  UserMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

BrHeader.defaultProps = {
  sticky: true,
  compact: false,
  logo: undefined,
  linkLogo: "/",
  handleClickLogo: undefined,
  sign: undefined,
  links: [],
  functions: [],
  loginWidget: undefined,
  title: "",
  subTitle: "",
  searchWidget: {},
  handleOpenMenu: () => {},
  handleOpenUserMenu: () => {},
  noLinks: false,
  noLogo: false,
  noSign: false,
  noSubtitle: false,
  noFunctions: false,
  noLogin: false,
  noSearch: false,
  noMenu: false,
  menu: undefined,
  UserMenu: undefined
};

export default BrHeader;
