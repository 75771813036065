import React from "react";
import PropTypes from "prop-types";
import BrTab from "./BrTab";
import BrTabContent from "./BrTabContent";
import BrTabPanel from "./BrTabPanel";
import BrTabsContext from "./BrTabsContext";
import BrTabss from "./BrTabs";

const BrTabs = props => {
  const { children, value, onChange, label, withCounter } = props;

  const handleChangeTab = tabId => {
    if (onChange) {
      onChange(tabId);
    }
  };

  const contextProps = {
    label,
    value,
    changeTab: handleChangeTab,
    withCounter
  };

  return (
    <BrTabsContext.Provider value={contextProps}>
      <div className="br-tabs" {...(withCounter && { "data-counter": "true" })}>
        {children}
      </div>
    </BrTabsContext.Provider>
  );
};

BrTabs.propTypes = {
  /** Label do componente, usado por questões de acessibilidade e por isso é obrigatório */
  label: PropTypes.string.isRequired,

  /** Tabs e conteúdo */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  /** Valor que representa a tab ativa */
  value: PropTypes.string.isRequired,

  /** Função que é disparada ao trocar de tab */
  onChange: PropTypes.func.isRequired,

  withCounter: PropTypes.bool
};

BrTabs.defaultProps = {
  withCounter: false
};

BrTabs.Tabs = BrTabss;
BrTabs.Tab = BrTab;
BrTabs.Content = BrTabContent;
BrTabs.Panel = BrTabPanel;

export default BrTabs;
