import React, { useEffect, useState } from "react";
import { useAutocomplete } from "@material-ui/lab";
import classnames from "classnames";
import PropTypes from "prop-types";
import BrSelectListBox from "./BrSelectListBox";
import "./styles.scss";

const BrSelect = props => {
  const [open, setOpen] = useState(false);

  const {
    id,
    label,
    items,
    disabled,
    help,
    sucesso,
    erro,
    multiple,
    placeholder,
    onChange,
    ...rest
  } = props;

  const handleChange = (event, option) => {
    let currentValue = null;
    if (multiple) {
      currentValue = option;
    } else {
      currentValue = option ? option.value : null;
    }
    if (onChange) {
      onChange(currentValue);
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    popupOpen
  } = useAutocomplete({
    id,
    options: items,
    multiple,
    getOptionLabel: option => option.label || "",
    getOptionSelected: (option, v) => option.value === v.value || v === "",
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onChange: handleChange,
    ...rest
  });

  const invalidProp = { invalid: "true" };
  const validProp = { valid: "true" };
  const selectId = `select-${id}`;

  useEffect(() => {
    if (multiple) {
      document.getElementById(selectId).setAttribute("multiple", "");
    }
  }, [multiple, selectId]);

  const getInputValue = () => {
    let result = "";
    if (value) {
      if (!multiple) {
        result = value.label;
      } else if (value.length === 0) {
        result = "";
      } else if (value.length === 1) {
        result = value[0].label;
      } else {
        result = `${value[0].label} + (${value.length - 1})`;
      }
    }
    return result;
  };

  const inputProps = {
    ...getInputProps(),
    ...(!popupOpen && { value: getInputValue() })
  };

  return (
    <>
      <div
        id={selectId}
        className="br-select"
        {...getRootProps()}
        {...(erro && invalidProp)}
        {...(sucesso && validProp)}
      >
        <div
          className="br-input"
          {...(erro && invalidProp)}
          {...(sucesso && validProp)}
        >
          <label htmlFor={id} {...getInputLabelProps()}>
            {label}
          </label>

          <input
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
          <button
            type="button"
            className="br-button circle small"
            tabIndex="-1"
            onClick={() => setOpen(!open)}
            disabled={disabled}
          >
            <span className="sr-only">Exibir lista</span>
            <i
              className={classnames(
                "fas",
                { "fa-angle-down": !open },
                { "fa-angle-up": open }
              )}
            />
          </button>
        </div>
        {groupedOptions.length > 0 ? (
          <BrSelectListBox
            options={groupedOptions}
            getListboxProps={getListboxProps}
            getOptionProps={getOptionProps}
            value={value}
            multiple={multiple}
          />
        ) : null}
      </div>
      {erro && (
        <div className="feedback color-danger mt-1">
          <i className="fas fa-times-circle" />
          <span>{erro}</span>
        </div>
      )}

      {sucesso && (
        <div className="feedback color-success mt-1">
          <i className="fas fa-check-circle" />
          <span>{sucesso}</span>
        </div>
      )}

      {help && <p className="help">{help}</p>}
    </>
  );
};

BrSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

BrSelect.defaultProps = {
  label: "",
  disabled: false,
  help: "",
  multiple: false,
  placeholder: "Selecione o item",
  onChange: null
};

export default BrSelect;
