import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import BrTooltip from "../BrTooltip";

const BrNotification = props => {
  const {
    id,
    mini,
    children,
    triggerRef,
    onClose,
    open,
    className,
    style
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <BrTooltip
      id={id}
      className={classnames("br-notification", className)}
      {...(mini && { mini: "" })}
      open={open}
      anchorEl={triggerRef.current}
      onClose={handleClose}
      placement="bottom"
      notification
      style={style}
    >
      <>{children}</>
    </BrTooltip>
  );
};

BrNotification.propTypes = {
  mini: PropTypes.bool,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  triggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string
};

BrNotification.defaultProps = {
  mini: false,
  className: ""
};

const Header = props => {
  const { userName, userEmail } = props;
  return (
    <div className="notification-header">
      <div className="user-name">{userName}</div>
      <div className="user-email">{userEmail}</div>
    </div>
  );
};

const Body = props => {
  const { children } = props;
  return <div className="notification-body">{children}</div>;
};

const Footer = props => {
  const { children } = props;
  return <div className="notification-footer">{children}</div>;
};

BrNotification.Header = Header;
BrNotification.Body = Body;
BrNotification.Footer = Footer;

export default BrNotification;
