import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";

export const BrModalFooter = props => {
  const { children } = props;
  return (
    <div className="br-modal-footer justify-content-end">
      {children}
    </div>

  );
};

BrModalFooter.propTypes = {
  children: PropTypes.node.isRequired
};

const BrModal = props => {
  const {
    visible,
    title,
    xsmall,
    small,
    medium,
    large,
    closable,
    onClose,
    children,
    ...rest
  } = props;

  const [open, setOpen] = useState(visible);
  useEffect(() => { setOpen(visible); }, [visible]);

  const classes = classnames("br-modal",
    xsmall ? 'is-xsmall' : '',
    small ? 'is-small' : '',
    medium ? 'is-medium' : '',
    large ? 'is-large' : ''
  );

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={`br-scrim is-foco ${open ? 'is-active' : ''}`}>
      <div className={classes} {...rest}>
        {
          title
          && (
            <div className="br-modal-header">
              <div className="br-modal-title" title={title}>{title}</div>
              {
                closable
                && (
                  <button className="close" type="button" data-dismiss="br-modal" aria-label="Close" onClick={handleClose}>
                    <i className="fas fa-times" aria-hidden="true" />
                  </button>
                )
              }
            </div>
          )
        }

        {children}

      </div>
    </div>
  );
};

BrModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  xsmall: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
};

BrModal.defaultProps = {
  visible: false,
  title: '',
  xsmall: false,
  small: false,
  medium: false,
  large: false,
  closable: true,
  onClose: null
};

export default BrModal;
