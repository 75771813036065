import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { createPortal } from "react-dom";

export const SIZES = {
  md: "md",
  sm: "sm",
  lg: "lg"
};

const BrLoading = props => {
  const {
    progress,
    show,
    dataProgress,
    label,
    cancelable,
    onCancel,
    size,
    overlay,
    overlayTarget
  } = props;

  const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      ...(overlayTarget && {
        position: "absolute",
        height: "100%",
        width: "100%"
      })
    }
  }));

  const classes = useStyles();

  const [isShow, setIsShow] = useState(show);

  useEffect(() => {
    if (isShow !== show) {
      setIsShow(show);
    }
  }, [show, isShow]);

  const handleCancel = () => {
    setIsShow(false);
    if (onCancel) {
      onCancel();
    }
  };

  let sizeProp;

  if (size) {
    switch (size) {
      case SIZES.sm:
        sizeProp = { sm: "true" };
        break;
      case SIZES.md:
        sizeProp = { md: "true" };
        break;
      case SIZES.lg:
        sizeProp = { lg: "true" };
        break;
      default:
        break;
    }
  }

  const renderLoadingBody = () => {
    if (progress) {
      return (
        <>
          <div className="br-loading" data-progress={dataProgress}>
            <div className="br-loading-mask" full="true">
              <div className="br-loading-fill" />
            </div>
            <div className="br-loading-mask">
              <div className="br-loading-fill" />
            </div>
          </div>
          {cancelable && (
            <button onClick={handleCancel} className="br-button" type="button">
              Cancelar
            </button>
          )}
        </>
      );
    }
    return (
      <>
        <div loading="true" {...sizeProp} {...(label && { label: "true" })} />
        {label && <span>{label}</span>}
      </>
    );
  };

  if (isShow) {
    if (overlay) {
      const target = overlayTarget
        ? document.getElementById(overlayTarget)
        : document.body;
      target.style.position = "relative";
      return createPortal(
        <Backdrop className={classes.backdrop} open={isShow}>
          {renderLoadingBody()}
        </Backdrop>,
        target
      );
    }
    return renderLoadingBody();
  }
  return null;
};

BrLoading.propTypes = {
  /** Define se o componente deve ser exibido na tela */
  show: PropTypes.bool.isRequired,

  /**
   * Define o tipo de loading.
   *
   * Se true, exibe quanto tempo uma operação vai demorar.
   * - Exibe uma progressão percentual de 0 a 100.
   * - Pode ser utilizado com ou sem o Botão Cancelar
   * - Possui apenas um tamanho.
   *
   * Se false, demonstra um tempo de espera não especificado.
   * - Exibe uma forma circular em movimento contínuo enquanto o
   *   processamento dos dados estiver ocorrendo.
   * - Pode ser usado com ou sem o Rótulo.
   */
  progress: PropTypes.bool,

  /** Valor atual quando progress = true */
  dataProgress: PropTypes.number,

  /** Rótulo exibido quando progress = false */
  label: PropTypes.string,

  /** Exibe botão de cancelar quando true */
  cancelable: PropTypes.bool,

  /** Callback acionado quando clicado no botão "Cancelar" */
  onCancel: PropTypes.func,

  /** Tamanhos disponíveis */
  size: PropTypes.oneOf([SIZES.md, SIZES.sm, SIZES.lg]),

  /** Indica se o loading deve ser renderizado sobre outro elemento */
  overlay: PropTypes.bool,

  /**
   * ID do elemento da tela onde o overlay ficará sobre.
   * Se não for definido vai ser na tela inteira (document.body).
   * O elemento, nesse caso, deve possuir, obrigatoriamente, CSS position "relative".
   */
  overlayTarget: PropTypes.string
};

BrLoading.defaultProps = {
  progress: false,
  dataProgress: undefined,
  label: undefined,
  size: SIZES.sm,
  cancelable: false,
  onCancel: undefined,
  overlay: false,
  overlayTarget: undefined
};

export default BrLoading;
