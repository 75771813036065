import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const BrRadio = props => {
  const {
    id,
    label,
    value,
    items,
    orientation,
    disabled,
    onChange,
    help,
    sucesso,
    erro,
    ...rest
  } = props;

  const [selectedValue, setSelectedValue] = useState(String(value));

  useEffect(() => { setSelectedValue(String(value)); }, [value]);

  const handleChange = event => {
    const input = event.target.value;

    setSelectedValue(input);
    if (onChange) {
      onChange(input);
    }
  };

  const isDisabled = disabled;
  const isInline = orientation === 'horizontal';
  const invalidProp = { invalid: "true" };
  const validProp = { valid: "true" };

  return (
    <>
      <p className="label">
        {label}
      </p>
      {
        items.map(i => (
          <div
            key={`div_${id}_${i.value}`}
            className={`br-radio ${isInline ? 'd-inline' : ''}`}
            {...erro && invalidProp}
            {...sucesso && validProp}
          >
            <input
              id={`${id}_${i.value}`}
              type="radio"
              name={`${id}_${i.value}`}
              aria-label={`${id}_${i.value}`}
              value={String(i.value)}
              onChange={handleChange}
              checked={selectedValue === String(i.value)}
              disabled={isDisabled}
              {...rest}
            />
            <label htmlFor={`${id}_${i.value}`}>
              {i.label}
            </label>
          </div>
        )
        )
      }

      {erro
        && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{erro}</span>
          </div>
        )}

      {sucesso
        && (
          <div className="feedback color-success mt-1">
            <i className="fas fa-check-circle" />
            <span>{sucesso}</span>
          </div>
        )}

      {help
        && (<p className="help">{help}</p>
        )}
    </>
  );
};

BrRadio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  onChange: PropTypes.func,
  help: PropTypes.string,
  erro: PropTypes.string,
  sucesso: PropTypes.string
};

BrRadio.defaultProps = {
  label: '',
  value: '',
  orientation: 'vertical',
  onChange: null,
  disabled: false,
  help: '',
  erro: '',
  sucesso: ''
};

export default BrRadio;
