import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Popper } from "@material-ui/core";
import classnames from "classnames";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const BrTooltip = props => {
  const {
    id,
    anchorEl,
    children,
    placement,
    popover,
    open,
    status,
    closeOnClickOutside,
    onClose,
    className,
    notification,
    style
  } = props;

  const [arrowPosition, setArrowPosition] = useState(null);
  const [isOpen, setIsOpen] = useState(open || false);

  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
  }, [open, isOpen]);

  const handleClose = () => {
    if (closeOnClickOutside) {
      setIsOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const instanceRef = useRef();
  useOnClickOutside(instanceRef, () => handleClose());

  const componentProps = {
    id,
    anchorEl,
    placement,
    open: isOpen,
    style
  };

  const componentAttributes = {
    "data-popper-placement": placement,
    ...(popover && { popover: "true" }),
    ...(isOpen && { "data-show": "true" }),
    ...(notification && { notification: "" })
  };

  if (status && status !== "") {
    componentAttributes[status] = "true";
  }

  const getArrowStyle = () => {
    switch (placement) {
      case "top":
      case "bottom":
        return {
          position: "absolute",
          left: 0,
          transform: `translate(${arrowPosition}px, 0px)`
        };
      case "left":
      case "right":
        return {
          top: "50%"
        };
      default:
        return {};
    }
  };

  const popperRef = pRef => {
    const aR = pRef && pRef.popper && pRef.popper.getBoundingClientRect();
    let ap;

    switch (placement) {
      case "top":
      case "bottom":
        ap = aR && aR.width / 2.1;
        break;
      default:
        break;
    }

    setArrowPosition(ap);
  };

  return (
    <Popper
      ref={instanceRef}
      popperRef={popperRef}
      {...componentProps}
      {...componentAttributes}
      className={classnames("br-tooltip", status, className)}
      modifiers={{
        arrow: {
          enabled: true
        }
      }}
    >
      <div data-popper-arrow="true" className="arrow" style={getArrowStyle()} />
      <button type="button" className="close" onClick={handleClose}>
        <i className="fas fa-times" />
      </button>
      {children}
    </Popper>
  );
};

const PopoverHeader = ({ children }) => (
  <div className="popover-header">{children}</div>
);

const PopoverBody = ({ children }) => (
  <div className="popover-body">{children}</div>
);

const PopoverFooter = ({ children }) => (
  <div className="popover-footer">{children}</div>
);

const PopoverImage = ({ children }) => (
  <div className="popover-image">{children}</div>
);

BrTooltip.propTypes = {
  /** Id do Tooltip */
  id: PropTypes.string.isRequired,

  /** Define o elemento ao qual o tooltip será vinculado */
  anchorEl: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func
  ]),

  /** Define se o tooltip está aberto */
  open: PropTypes.bool.isRequired,

  /** Conteúdo do tooltipo */
  children: PropTypes.element.isRequired,

  /** Posição de exibição do tooltip em relação ao pai */
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),

  /** Definir as cores dos tooltips */
  status: PropTypes.oneOf(["success", "warning", "info", "error"]),

  /** Tipo de tooltip que permite a criação de conteúdos mais complexos */
  popover: PropTypes.bool,

  /**
   * Atributo que pode ser definido quando se deseja que
   * o tooltip apareça no inicio do carregamento
   */
  active: PropTypes.bool,

  /**
   * Atributo que determina após quantos milisegundos o
   * tooltip deve ser automaticamente ocultado
   */
  timer: PropTypes.number,

  /** Define se o Tooltip deve ser fechado quando clicado fora da área visível dele */
  closeOnClickOutside: PropTypes.bool,

  /** Função callback executada ao fechar o tooltip */
  onClose: PropTypes.func,

  /** Classes CSS */
  className: PropTypes.string,

  notification: PropTypes.bool
};

BrTooltip.defaultProps = {
  anchorEl: undefined,
  placement: "top",
  status: undefined,
  popover: false,
  active: false,
  timer: undefined,
  closeOnClickOutside: true,
  onClose: () => {},
  className: "",
  notification: false
};

BrTooltip.PopoverHeader = PopoverHeader;
BrTooltip.PopoverBody = PopoverBody;
BrTooltip.PopoverFooter = PopoverFooter;
BrTooltip.PopoverImage = PopoverImage;

export default BrTooltip;
