import PropTypes from "prop-types";
import React from "react";

const BrCard = props => {
  const { children, title } = props;

  return (
    <div className="br-card">
      <div className="front">
        {title
          && (
            <div className="header d-flex justify-content-between">
              <div className="h5">{title}</div>
            </div>
          )}
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
};

BrCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

BrCard.defaultProps = {
  title: ''
};

export default BrCard;
