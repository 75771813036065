import React, { useContext } from "react";

import BrDatepickerContext from "./BrDatepickerContext";

const CustomInput = inputProps => {
  const contextValues = useContext(BrDatepickerContext);

  const { id, label, isOpen, setIsOpen, allowInput, erro, sucesso, help } = contextValues;
  const { value, onChange, disabled, inputRef } = inputProps;

  const usedProps = {
    value,
    onChange,
    disabled,
    ref: inputRef
  };

  const invalidProp = { invalid: "true" };
  const validProp = { valid: "true" };

  if (!allowInput) {
    usedProps.readOnly = true;
  }

  const handleFocus = () => {
    if (!allowInput) {
      setIsOpen(true);
    }
  };

  return (
    <div className="br-datepicker">
      <div className="br-input" {...erro && invalidProp} {...sucesso && validProp}>
        <label htmlFor={id}>{label}</label>
        <input id={id} type="text" {...usedProps} onClick={handleFocus} />
        <button
          className="icon"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Abrir Timepicker</span>
          <i
            id={`${id}_icon`}
            className="fas fa-calendar-alt"
            aria-hidden="true"
          />
        </button>
      </div>

      {erro
        && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{erro}</span>
          </div>
        )}

      {sucesso
        && (
          <div className="feedback color-success mt-1">
            <i className="fas fa-check-circle" />
            <span>{sucesso}</span>
          </div>
        )}

      {help
        && (<p className="help">{help}</p>
        )}
    </div>
  );
};

export default CustomInput;
