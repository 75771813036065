import React, { useContext } from "react";
import BrTabsContext from "./BrTabsContext";

const BrTabs = props => {
  const context = useContext(BrTabsContext);
  const { children } = props;
  const { label } = context;
  return (
    <nav className="tab-nav" aria-label={label}>
      <ul>{children}</ul>
    </nav>
  );
};

export default BrTabs;
