import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

const BrCheckbox = props => {
  const {
    id,
    label,
    values,
    items,
    columns,
    disabled,
    onChange,
    help,
    sucesso,
    erro,
    ...rest
  } = props;

  const [selectedValues, setSelectedValues] = useState(values.map(v => String(v)));

  useEffect(() => { setSelectedValues(values.map(v => String(v))); }, [values]);

  const handleChange = event => {
    const { value, checked } = event.target;
    let newValues = selectedValues || [];

    if (checked) {
      newValues = [...newValues, value];
    } else {
      newValues = selectedValues.filter(v => (v !== value));
    }
    setSelectedValues(newValues);
    if (onChange) {
      onChange(value, checked);
    }
  };

  const isDisabled = disabled;
  const invalidProp = { invalid: "true" };
  const validProp = { valid: "true" };

  const qtdItemsPerColumn = items ? Math.ceil(items.length / columns) : 0;
  const itemsPerColumn = items
    ? new Array(columns)
      .fill()
      .map((_, i) =>
        items.slice(i * qtdItemsPerColumn, i * qtdItemsPerColumn + qtdItemsPerColumn)
      )
    : [];

  return (
    <>
      {
        label
        && (
          <p className="label">
            {label}
          </p>
        )
      }
      <Grid container spacing={2}>

        {
          itemsPerColumn.map((columnItems, index) =>
            (
              <Grid key={`column_${id}_${columnItems.length / index}`} item xs={12 / columns}>

                {columnItems.map(i => (
                  <div
                    key={`div_${id}_${i.value}`}
                    className="br-checkbox"
                    {...erro && invalidProp}
                    {...sucesso && validProp}
                  >
                    <input
                      id={`${id}_${i.value}`}
                      type="checkbox"
                      aria-label={`${id}_${i.value}`}
                      value={String(i.value)}
                      disabled={isDisabled}
                      checked={selectedValues && selectedValues.some(v => v === String(i.value))}
                      onChange={handleChange}
                      {...rest}
                    />
                    <label htmlFor={`${id}_${i.value}`}>
                      {i.label}
                    </label>
                  </div>
                ))}
              </Grid>
            )
          )
        }
      </Grid>

      {erro
        && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{erro}</span>
          </div>
        )}

      {sucesso
        && (
          <div className="feedback color-success mt-1">
            <i className="fas fa-check-circle" />
            <span>{sucesso}</span>
          </div>
        )}

      {help
        && (
          <p className="help">{help}</p>
        )}
    </>
  );
};

BrCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  columns: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  help: PropTypes.string,
  erro: PropTypes.string,
  sucesso: PropTypes.string
};

BrCheckbox.defaultProps = {
  label: '',
  values: [],
  columns: 1,
  onChange: null,
  disabled: false,
  help: '',
  erro: '',
  sucesso: ''
};

export default BrCheckbox;
