import React, { createRef, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import PropTypes from "prop-types";
import brLocale from "date-fns/locale/pt-BR";

import CustomInput from "./CustomInput";
import BrDatepickerContext from "./BrDatepickerContext";

const BrDatepicker = props => {
  const dateRef = createRef();

  const {
    date,
    open,
    mask,
    disableToolbar,
    id,
    label,
    onChange,
    variant,
    allowInput,
    disableFuture,
    disabled,
    erro,
    sucesso,
    help,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const [anchorEl, setAnchorEl] = useState();

  const contextProps = {
    setIsOpen,
    id,
    label,
    isOpen,
    allowInput,
    erro,
    sucesso,
    help
  };

  const handleChange = value => {
    if (onChange) {
      onChange(value);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!anchorEl) {
      setAnchorEl(dateRef.current);
    }
  }, [dateRef, anchorEl]);

  return (
    <BrDatepickerContext.Provider value={contextProps}>
      <div ref={dateRef}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
          <KeyboardDatePicker
            open={isOpen && !disabled}
            value={date}
            {...(disableToolbar ? { disableToolbar: true } : "")}
            {...(disableFuture ? { disableFuture: true } : "")}
            format={mask}
            onChange={handleChange}
            inputFormat={mask}
            onClose={() => setIsOpen(false)}
            TextFieldComponent={CustomInput}
            PopoverProps={{
              anchorEl
            }}
            variant={variant}
            autoOk
            {...(disabled ? { disabled: true } : "")}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </div>
    </BrDatepickerContext.Provider>
  );
};

BrDatepicker.propTypes = {
  /** Data selecionada */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,

  /** Função que é executada ao selecionar uma data */
  onChange: PropTypes.func.isRequired,

  /** Modo de funcionamento do Date Picker */
  mode: PropTypes.oneOf(["single", "range"]),

  /** Label associado ao input */
  label: PropTypes.string.isRequired,

  /** Define se o valor pode ser digitado pelo usuário no input */
  allowInput: PropTypes.bool,

  /** Tipo do Datepicker */
  type: PropTypes.oneOf(["text", "time", "datetime-local"]),

  /** ID da instância do componente na árvore DOM */
  id: PropTypes.string.isRequired,

  /** Define se o datepicker está aberto */
  open: PropTypes.bool,

  /** Mascara a ser aplicada na data */
  mask: PropTypes.string,

  /** Define se exibe toolbar com seleção de ano */
  disableToolbar: PropTypes.bool,

  /** Forma como é exido o componente de selecção da data */
  variant: PropTypes.oneOf(["inline", "static", "dialog"])
};

BrDatepicker.defaultProps = {
  mode: "single",
  allowInput: false,
  type: "text",
  open: false,
  mask: "dd/MM/yyyy",
  disableToolbar: false,
  variant: "inline"
};

export default BrDatepicker;
