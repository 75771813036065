import React, { useState } from 'react';
import classnames from "classnames";
import PropTypes from "prop-types";

import BrButton from '../BrButton';

const BrMessage = props => {
  const {
    success,
    error,
    info,
    warning,
    children,
    closable,
    onClose,
    ...rest
  } = props;

  const [closed, setClosed] = useState(false);

  const divClasses = classnames("br-message",
    success ? 'success' : '',
    error ? 'danger' : '',
    { info, warning }
  );

  const iconClasses = classnames("fas ",
    success ? 'fa-check-circle' : '',
    error ? 'fa-times-circle' : '',
    info ? 'fa-info-circle' : '',
    warning ? 'fa-exclamation-triangle' : '', "fa-lg"
  );

  const handleClose = () => {
    setClosed(true);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {
        !closed
        && (
          <div className={divClasses} role="alert" {...rest}>
            <div className="icon" aria-label="icone mensagem">
              <i className={iconClasses} aria-hidden="true" />
            </div>
            <div className="content" aria-label="informações do conteúdo">
              {children}
            </div>
            {
              closable
              && (
                <div className="close">
                  <BrButton circle small onClick={handleClose}>
                    <i className="fas fa-times" aria-hidden="true" />
                  </BrButton>
                </div>
              )
            }
          </div>
        )
      }
    </>
  );
};

BrMessage.propTypes = {
  children: PropTypes.node.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func
};

BrMessage.defaultProps = {
  success: false,
  error: false,
  info: false,
  warning: false,
  closable: false,
  onClose: null
};

export default BrMessage;
