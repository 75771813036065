import React, { useContext } from "react";
import classnames from "classnames";
import BrTabsContext from "./BrTabsContext";

const BrTabPanel = props => {
  const context = useContext(BrTabsContext);
  const { value } = context;
  const { contentFor, children } = props;
  return (
    <div
      className={classnames("tab-panel", { "is-active": contentFor === value })}
      id={contentFor}
    >
      {children}
    </div>
  );
};

export default BrTabPanel;
