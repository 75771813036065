import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BrTabsContext from "./BrTabsContext";

const BrTab = props => {
  const context = useContext(BrTabsContext);
  const { label, id, notTab, outcome, counterValue, iconClass } = props;
  const { value, changeTab, withCounter } = context;
  const labelContent = <span className="name">{label}</span>;

  return (
    <li
      className={classnames("tab-item", { "is-active": id === value })}
      {...(notTab && { "not-tab": "true" })}
    >
      {notTab ? (
        labelContent
      ) : (
        <button type="button" aria-label={label} onClick={() => changeTab(id)}>
          {iconClass && <i className={iconClass} />}
          <span className="name">{labelContent}</span>
        </button>
      )}
      {notTab && outcome && (
        <p className="outcome">
          {outcome.label}
          <span>{outcome.value}</span>
        </p>
      )}
      {withCounter && counterValue && (
        <span className="results">{`( ${counterValue} )`}</span>
      )}
    </li>
  );
};

BrTab.propTypes = {
  /** Identificado da tab */
  id: PropTypes.string.isRequired,

  /** Rótulo dessa tab */
  label: PropTypes.string.isRequired,

  /** Elemento que não se comporta como aba */
  notTab: PropTypes.bool,

  /** Informações passíveis de exibição quando notTab = true */
  outcome: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),

  /** Valor exibido abaixo do label da tab quando o tipo BrTabs.withCounter = true */
  counterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Classs CSS do ícone */
  iconClass: PropTypes.string
};

BrTab.defaultProps = {
  notTab: false,
  outcome: undefined,
  counterValue: undefined,
  iconClass: undefined
};

export default BrTab;
